<template>
    <a-spin :spinning="loading">
        <div class="modal-header">
            <div class="modal-title">
                <a-icon type="medicine-box" />
                <span v-if="interfaceFieldId">编辑接口参数</span>
                <span v-else>添加接口参数</span>
            </div>
        </div>
        <div>
            <a-row :gutter="[24, 12]">
                <a-col :span="24">
                    <div style="margin-bottom: 10px"><font color="red">*</font> 参数名称：</div>
                    <a-input placeholder="请输入参数名称" v-model="model.name"></a-input>
                </a-col>
                <a-col :span="24">
                    <div style="margin-bottom: 10px">描述：</div>
                    <a-input placeholder="请输入描述" v-model="model.describe"></a-input>
                </a-col>
                <a-col :span="24">
                    <div style="margin-bottom: 10px"><font color="red">*</font>参数类型：</div>
                    <a-select
                        show-search
                        allowClear
                        placeholder="参数类型"
                        option-filter-prop="children"
                        style="width: 100%"
                        v-model="model.type"
                    >
                        <a-select-option v-for="item in FiledTypes" :key="item.key">
                            {{ item.value }}
                        </a-select-option>
                    </a-select>
                </a-col>
            </a-row>
        </div>
        <!-- 按钮 -->
        <div class="modal-footer">
            <a-button @click="close()" type="button">
                <a-icon type="close-circle" />
                {{ l('Cancel') }}
            </a-button>
            <a-button :type="'primary'" @click="save()">
                <a-icon type="save" />
                {{ l('Save') }}
            </a-button>
        </div>
    </a-spin>
</template>

<script>
import ModalComponentBase from '@/shared/component-base/modal-component-base';
import { InterfaceFieIdServiceProxy, CreateOrUpdateInterfaceFieIdInput } from '@/shared/service-proxies';

export default {
    mixins: [ModalComponentBase],
    data() {
        return {
            interfaceId: undefined,
            interfaceFieldId: undefined,

            model: {
                name: '',
                describe: '',
                type: undefined,
            },

            FiledTypes: [],
        };
    },
    async mounted() {
        this.fullData();
        this._interfaceFieIdServiceProxy = new InterfaceFieIdServiceProxy(this.$apiUrl, this.$api);
        //获取所有参数类型
        this.FiledTypes = await this._interfaceFieIdServiceProxy.getFiledType();

        if (this.interfaceFieldId) {
            this._interfaceFieIdServiceProxy
                .getForEdit(this.interfaceFieldId)
                .finally(() => {
                    this.loading = false;
                })
                .then((res) => {
                    this.model = res.interfaceFieId;
                    this.model.type = this.model.type == 0 ? undefined : this.model.type;
                });
        }
    },
    methods: {
        save() {
            if (!this.model.name) {
                abp.message.warn('请输入参数名称');
                return;
            }
            if (!this.model.type) {
                abp.message.warn('请选择参数类型');
                return;
            }
            this.loading = true;
            const input = new CreateOrUpdateInterfaceFieIdInput();
            input.interfaceFieId.interfaceId = this.interfaceId;
            input.interfaceFieId.id = this.interfaceFieldId;
            input.interfaceFieId.name = this.model.name;
            input.interfaceFieId.describe = this.model.describe;
            input.interfaceFieId.type = this.model.type;
            this._interfaceFieIdServiceProxy
                .createOrUpdate(input)
                .finally(() => {
                    this.loading = false;
                })
                .then(() => {
                    this.success(true);
                    this.$notification['success']({
                        message: this.l('SavedSuccessfully'),
                    });
                });
        },
    },
};
</script>

<style lang="less" scoped></style>
