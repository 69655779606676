<template>
    <a-config-provider :locale="zh_CN">
        <a-row>
            <a-col :span="8">
                <a-spin :spinning="loading">
                    <a-card>
                        <div class="q-flex-col">
                            <a-input-search
                                style="width: 240px"
                                name="filterText"
                                :placeholder="l('SearchWithThreeDot')"
                                @search="searchInterface"
                                enterButton
                                v-model="filterText"
                                v-decorator="['filterText']"
                            />
                            <div class="q-padding-updown-10">
                                <a-button :type="'primary'" @click="createInterface">
                                    <a-icon type="plus" />
                                    <span>{{ l('Create') }}</span>
                                </a-button>
                                <a-button :type="'primary'" @click="editInterface">
                                    <a-icon type="edit" />
                                    <span>{{ l('Edit') }}</span>
                                </a-button>
                                <a-button :type="'danger'" @click="deleteInterface">
                                    <a-icon type="delete" />
                                    <span>{{ l('Delete') }}</span>
                                </a-button>
                            </div>
                        </div>
                        <a-table
                            class="list-table"
                            :pagination="false"
                            :customRow="customRow"
                            :columns="columns"
                            :rowKey="(tableDatas) => tableDatas.id"
                            :dataSource="tableData"
                        >
                            <a-checkbox slot="checkbox" slot-scope="text, record" :checked="record.checked">
                            </a-checkbox>
                        </a-table>
                        <a-pagination
                            class="pagination"
                            :total="totalItems"
                            :showTotal="showTotalFun"
                            v-model="pageNumber"
                            @change="onChange"
                        />
                    </a-card>
                </a-spin>
            </a-col>
            <a-col :span="16">
                <a-spin :spinning="loadingField">
                    <a-card class="q-ml-10">
                        <div class="q-flex-col">
                            <div class="q-flex-col">
                                <a-input-search
                                    style="width: 240px"
                                    name="filterText"
                                    :placeholder="l('SearchWithThreeDot')"
                                    @search="searchInterfaceField"
                                    enterButton
                                    v-model="filterTextField"
                                />
                                <div class="q-padding-updown-10 ">
                                    <a-button :type="'primary'" @click="createInterfaceField">
                                        <a-icon type="plus" />
                                        <span>{{ l('Create') }}</span>
                                    </a-button>
                                    <a-button :type="'primary'" @click="editInterfaceField">
                                        <a-icon type="edit" />
                                        <span>{{ l('Edit') }}</span>
                                    </a-button>
                                    <a-button :type="'danger'" @click="deleteInterfaceField">
                                        <a-icon type="delete" />
                                        <span>{{ l('Delete') }}</span>
                                    </a-button>
                                </div>
                            </div>
                            <a-table
                                class="list-table"
                                :pagination="false"
                                :customRow="customRowField"
                                :columns="columnsField"
                                :rowKey="(tableDatas) => tableDatas.id"
                                :dataSource="tableDataField"
                            >
                                <a-checkbox slot="checkbox" slot-scope="text, record" :checked="record.checked">
                                </a-checkbox>
                            </a-table>
                            <div>
                                <a-pagination
                                    class="pagination"
                                    :total="totalItemsField"
                                    :showTotal="showTotalFunField"
                                    v-model="pageNumberField"
                                    @change="onFieldChange"
                                />
                            </div>
                        </div>
                    </a-card>
                </a-spin>
            </a-col>
        </a-row>
    </a-config-provider>
</template>

<script>
import { AppComponentBase } from '@/shared/component-base';
import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN';

import { InterfaceServiceProxy, InterfaceFieIdServiceProxy } from '@/shared/service-proxies';

import createOrEditInterfaceField from './create-or-edit-interface-field/create-or-edit-interface-field.vue';
import createOrEditInterface from './create-or-edit-interface/create-or-edit-interface.vue';
import ModalHelper from '@/shared/helpers/modal/modal-helper';
let _this;
export default {
    name: 'interface-manager',
    mixins: [AppComponentBase],
    components: { createOrEditInterface, createOrEditInterfaceField },
    data() {
        return {
            zh_CN,
            // 总数
            totalItems: 0,
            // 当前页码
            pageNumber: 1,
            // 每页多少条数据
            pageSize: 10,
            filterText: '',
            columns: [
                {
                    title: '',
                    dataIndex: 'checkbox',
                    sorter: false,
                    fixed: 'left',
                    width: 50,
                    align: 'center',
                    scopedSlots: { customRender: 'checkbox' },
                },
                {
                    title: this.l('Name'),
                    dataIndex: 'name',
                    sorter: false,
                    ellipsis: true,
                    align: 'center',
                    scopedSlots: { customRender: 'name' },
                },
                {
                    title: '接口地址',
                    dataIndex: 'address',
                    sorter: false,
                    ellipsis: true,
                    align: 'center',
                    scopedSlots: { customRender: 'address' },
                },
                {
                    title: '接口类型',
                    dataIndex: 'typeName',
                    sorter: false,
                    ellipsis: true,
                    align: 'center',
                    scopedSlots: { customRender: 'typeName' },
                },
            ],
            tableData: [],

            //当前选中的接口ID
            interfaceId: undefined,

            /*****************接口字段 */
            // 总数
            totalItemsField: 0,
            // 当前页码
            pageNumberField: 1,
            // 每页多少条数据
            pageSizeField: 10,
            filterTextField: '',
            columnsField: [
                {
                    title: '',
                    dataIndex: 'checkbox',
                    sorter: false,
                    fixed: 'left',
                    width: 50,
                    align: 'center',
                    scopedSlots: { customRender: 'checkbox' },
                },
                {
                    title: this.l('Name'),
                    dataIndex: 'name',
                    sorter: false,
                    ellipsis: true,
                    align: 'center',
                    scopedSlots: { customRender: 'name' },
                },
                {
                    title: '描述',
                    dataIndex: 'describe',
                    sorter: false,
                    ellipsis: true,
                    align: 'center',
                    scopedSlots: { customRender: 'describe' },
                },
                {
                    title: '字段类型',
                    dataIndex: 'typeName',
                    sorter: false,
                    ellipsis: true,
                    align: 'center',
                    scopedSlots: { customRender: 'typeName' },
                },
            ],
            tableDataField: [],

            //当前选中的字段ID
            interfaceFieldId: undefined,
            loadingField: false,

            selectedRow: {},
            selectedFieldRow: {},
        };
    },
    mounted() {
        _this = this;
        this._interfaceServiceProxy = new InterfaceServiceProxy(this.$apiUrl, this.$api);
        this._interfaceFieIdServiceProxy = new InterfaceFieIdServiceProxy(this.$apiUrl, this.$api);

        this.getInterface();
    },
    methods: {
        searchInterface() {
            this.pageNumber = 1;
            this.getInterface();
        },
        getInterface() {
            this.loading = true;
            this._interfaceServiceProxy
                .getPaged(this.filterText, undefined, this.pageSize, (this.pageNumber - 1) * this.pageSize)
                .finally(() => {
                    this.loading = false;
                })
                .then((res) => {
                    this.tableData = res.items;
                    this.totalItems = res.totalCount;
                    this.selectedRow = this.tableData[0];
                    this.interfaceId = this.selectedRow.id;
                    this.selectedRow.checked = true;

                    this.getInterfaceField();
                });
        },
        //添加接口
        createInterface() {
            ModalHelper.create(
                createOrEditInterface,
                {},
                {
                    width: '400px',
                }
            ).subscribe((res) => {
                if (res) {
                    this.getInterface();
                }
            });
        },
        //编辑接口
        editInterface() {
            ModalHelper.create(
                createOrEditInterface,
                { id: this.interfaceId },
                {
                    width: '400px',
                }
            ).subscribe((res) => {
                if (res) {
                    this.getInterface();
                }
            });
        },
        //批量删除接口
        deleteInterface() {
            const ids = this.tableData.filter((item) => item.checked).map((item) => item.id);
            if (ids && ids.length < 1) {
                abp.message.warn('请至少选择一项');
                return;
            }
            this.$confirm({
                title: '确认信息',
                content: '确定要删除选中的记录吗？',
                cancelText: '取消',
                okText: '确认',
                onOk: () => {
                    this._interfaceServiceProxy
                        .batchDelete(ids)
                        .finally(() => {})
                        .then((res) => {
                            this.getInterface();
                            this.$notification['success']({
                                message: '删除成功',
                            });
                        });
                },
            });
        },
        /**
         * 表格行点击
         * @param record 点击行的数据
         * @param index 点击行的下标
         */
        customRow: (record, index) => ({
            on: {
                click: () => {
                    _this.interfaceId = record.id;
                    //设置当前点击的数据
                    _this.selectedRow = record;
                    //清空选择
                    _this.tableData.map((item) => (item.checked = false));
                    //赋值选择
                    record.checked = !record.checked;
                    _this.searchInterfaceField();
                    //强制更新布局
                    _this.$forceUpdate();
                },
            },
        }),
        /**
         * 分页事件
         */
        showTotalFun() {
            return `共有${this.totalItems}条`;
        },
        /**
         * 分页
         */
        onChange(page, pageSize) {
            this.pageNumber = page;
            this.getInterface();
        },

        /*********************接口字段 */

        searchInterfaceField() {
            this.pageNumberField = 1;
            this.getInterfaceField();
        },
        getInterfaceField() {
            this.loadingField = true;
            this._interfaceFieIdServiceProxy
                .getPaged(
                    this.interfaceId,
                    this.filterTextField,
                    undefined,
                    this.pageSizeField,
                    (this.pageNumberField - 1) * this.pageSizeField
                )
                .finally(() => {
                    this.loadingField = false;
                })
                .then((res) => {
                    this.tableDataField = res.items;
                    this.totalItemsField = res.totalCount;
                });
        },
        //添加接口字段
        createInterfaceField() {
            ModalHelper.create(
                createOrEditInterfaceField,
                { interfaceId: this.interfaceId },
                {
                    width: '400px',
                }
            ).subscribe((res) => {
                if (res) {
                    this.getInterfaceField();
                }
            });
        },
        //编辑接口字段
        editInterfaceField() {
            console.log('interfaceFieldId', this.interfaceFieldId);
            if (!this.interfaceFieldId) {
                abp.message.warn('请选择接口字段');
                return;
            }
            ModalHelper.create(
                createOrEditInterfaceField,
                { interfaceId: this.interfaceId, interfaceFieldId: this.interfaceFieldId },
                {
                    width: '400px',
                }
            ).subscribe((res) => {
                if (res) {
                    this.getInterfaceField();
                }
            });
        },
        //删除接口字段
        deleteInterfaceField() {
            this.$confirm({
                title: '确认信息',
                content: '确定要删除选中的记录吗？',
                cancelText: '取消',
                okText: '确认',
                onOk: () => {
                    this.loadingField = true;
                    this._interfaceFieIdServiceProxy
                        .delete(this.interfaceFieldId)
                        .finally(() => {
                            this.loadingField = false;
                        })
                        .then(() => {
                            this.getInterface();
                            this.$notification['success']({
                                message: '删除成功',
                            });
                        });
                },
            });
        },
        /**
         * 表格行点击
         * @param record 点击行的数据
         * @param index 点击行的下标
         */
        customRowField: (record, index) => ({
            on: {
                click: () => {
                    _this.interfaceFieldId = record.id;
                    //清空选择
                    _this.tableDataField.map((item) => (item.checked = false));
                    //赋值选择
                    record.checked = !record.checked;
                    //强制更新布局
                    _this.$forceUpdate();
                },
            },
        }),
        /**
         * 分页事件
         */
        showTotalFunField() {
            return `共有${this.totalItemsField}条`;
        },
        /**
         * 分页
         */
        onFieldChange(page, pageSize) {
            this.pageNumberField = page;
            this.getInterfaceField();
        },
    },
};
</script>

<style lang="less" scoped>
.pagination {
    margin: 10px auto;
    text-align: right;
}
</style>
