<template>
    <a-spin :spinning="loading">
        <div class="modal-header">
            <div class="modal-title">
                <a-icon type="medicine-box" />
                <span v-if="id">编辑接口</span>
                <span v-else>添加接口</span>
            </div>
        </div>
        <div>
            <a-row :gutter="[24, 12]">
                <a-col :span="24">
                    <div style="margin-bottom: 10px"><font color="red">*</font>名称：</div>
                    <a-input placeholder="请输入名称" v-model="model.name"></a-input>
                </a-col>
                <a-col :span="24">
                    <div style="margin-bottom: 10px"><font color="red">*</font>接口地址：</div>
                    <a-input placeholder="请输入接口地址" v-model="model.address"></a-input>
                </a-col>
                <a-col :span="24">
                    <div style="margin-bottom: 10px">接口类型：</div>
                    <a-select
                        show-search
                        allowClear
                        placeholder="接口类型"
                        option-filter-prop="children"
                        style="width: 100%"
                        v-model="model.typeCode"
                    >
                        <a-select-option v-for="item in interfaceTypes" :key="item.itemDetailCode">
                            {{ item.itemDetailName }}
                        </a-select-option>
                    </a-select>
                </a-col>
            </a-row>
        </div>
        <!-- 按钮 -->
        <div class="modal-footer">
            <a-button @click="close()" type="button">
                <a-icon type="close-circle" />
                {{ l('Cancel') }}
            </a-button>
            <a-button :type="'primary'" @click="save()">
                <a-icon type="save" />
                {{ l('Save') }}
            </a-button>
        </div>
    </a-spin>
</template>

<script>
import ModalComponentBase from '@/shared/component-base/modal-component-base';
import { InterfaceServiceProxy, CreateOrUpdateInterfaceInput } from '@/shared/service-proxies';

export default {
    mixins: [ModalComponentBase],
    data() {
        return {
            id: undefined,

            model: {
                name: '',
                address: '',
                typeCode: undefined,
            },
            interfaceTypes: [],
        };
    },
    async mounted() {
        this.fullData();
        this._interfaceServiceProxy = new InterfaceServiceProxy(this.$apiUrl, this.$api);
        //获取所有接口类型
        this.interfaceTypes = await this._interfaceServiceProxy.getInterfaceTypeItem();
        if (this.id) {
            this._interfaceServiceProxy
                .getForEdit(this.id)
                .finally(() => {
                    this.loading = false;
                })
                .then((res) => {
                    this.model = res.interface;
                });
        }
    },
    methods: {
        save() {
            if (!this.model.name) {
                abp.message.warn('请输入名称');
                return;
            }
            if (!this.model.address) {
                abp.message.warn('请输入接口地址');
                return;
            }
            this.loading = true;
            const input = new CreateOrUpdateInterfaceInput();
            input.interface.name = this.model.name;
            input.interface.address = this.model.address;
            input.interface.typeCode = this.model.typeCode;
            input.interface.id = this.id;
            this._interfaceServiceProxy
                .createOrUpdate(input)
                .finally(() => {
                    this.loading = false;
                })
                .then((res) => {
                    this.success(true);
                    this.$notification['success']({
                        message: this.l('SavedSuccessfully'),
                    });
                });
        },
    },
};
</script>

<style lang="less" scoped></style>
